import { PageContextType, PageLocaleLink } from "components";
import { hasValue } from "utils";

const languages = ["en", "de"] as const;
export type Language = typeof languages[number];

const defaultLanguage: Language = process.env.GATSBY_DEFAULT_LOCALE as Language;

export const TranslationTheme = {
  languages,
  defaultLanguage,
  /** namespace that should be accessed per default, will be used if none was provided */
  defaultNamespace: <string>"global",
  /** should debugging be turned on (Important: Turn off after debugging otherwise this will be active on prod) */
  isDebugging: <boolean>false,
  /** callback function to extract the language from the given pageContext */
  getPageLanguageFromPageContext: <(pageContext: PageContextType) => Language>((
    pageContext
  ) => {
    if (!hasValue(pageContext.locale)) {
      console.warn(
        `No locale found in pageContext. Default language defined in TranslationTheme ("${defaultLanguage}") will be used as a fallback value.`
      );

      return defaultLanguage;
    }

    return pageContext.locale;
  }),
  /** callback function to extract the localeLinks from the given pageContext */
  getPageLocaleLinksFromPageContext: <
    (pageContext: PageContextType) => PageLocaleLink[]
  >((pageContext) => {
    if (!hasValue(pageContext.altLinks)) {
      console.warn(
        `No localeLinks found in pageContext. An empty array will be used as fallback value.`
      );
    }

    return hasValue(pageContext.altLinks)
      ? (pageContext.altLinks as PageLocaleLink[])
      : [];
  }),
};
